import "moment/locale/fr";

import _mapKeys from "lodash/mapKeys";
import _mapValues from "lodash/mapValues";
import _reject from "lodash/reject";
import moment from "moment";
import React, { Component } from "react";
import Modal from "react-modal";
import { connect } from "react-redux";
import { createSelector } from "reselect";

import {
  JobSelect,
  LocationSelect,
} from "../../../components/JobAndLocationSelect.jsx";
import { post } from "../../../services/api.js";
import { convertSearchesToInterests, formatSearch } from "../util.js";

/**
 * Partie du formulaire de destinataire gérant son profil (informations + recherches)
 */
export class ProfileSearch extends Component {
  onAddSearch = () => {
    this.props.onChangeSearches([
      ...this.props.searches,
      {
        jobs: [],
        locations: [],
      },
    ]);
  };

  onRemoveSearch = (index) => () => {
    this.props.onChangeSearches(
      _reject(this.props.searches, (_, _index) => index === _index)
    );
  };

  onChangeJobsOrLocations = (index, property) => (items) => {
    const searches = this.props.searches.map((search, _index) => {
      if (index === _index) {
        return {
          ...search,
          [property]: items,
        };
      } else {
        return search;
      }
    });

    this.props.onChangeSearches(searches);
  };

  render() {
    const { searches } = this.props;

    return (
      <fieldset className="ProfileSearch form-fieldset">
        <legend className="form-legend">Profils de recherche</legend>

        <ol className="ProfileSearch-Items">
          {searches.map((search, index) => (
            <li key={index}>
              <h3 className="ProfileSearch-Item-Title">Profil {index + 1}</h3>
              <Search
                key={index}
                searches={searches}
                search={search}
                index={index}
                onChangeJobsOrLocations={this.onChangeJobsOrLocations}
                onRemoveSearch={this.onRemoveSearch}
              />
            </li>
          ))}
        </ol>

        <button
          className="ProfileSearch-AddItem"
          type="button"
          onClick={this.onAddSearch}
        >
          <i className="icon material-icons">add_circle_outline</i> Ajouter une
          recherche
        </button>
      </fieldset>
    );
  }
}

class Search extends Component {
  state = {
    offersOpened: false,
    offers: [],
    offersStats: null,
    loading: true,
    error: null,
  };

  onOpenOffers = () => this.setState({ offersOpened: true });
  onCloseOffers = () => this.setState({ offersOpened: false });

  componentWillMount() {
    this.updateOffers(this.props.search);
  }

  componentWillReceiveProps({ search }) {
    this.updateOffers(search);
  }

  updateOffers = (search) => {
    if (
      search &&
      search.jobs &&
      search.jobs.length &&
      search.locations &&
      search.locations.length
    ) {
      post(`/api/app/offer`, convertSearchesToInterests([search]))
        .then(({ offers = [], stats = null }) =>
          this.setState({
            loading: false,
            offers,
            offersStats: stats,
            error: null,
          })
        )
        .catch((err) =>
          this.setState({
            loading: false,
            offers: [],
            offersStats: null,
            error: err && err.message ? err.message : "Une erreur est survenue",
          })
        );
    } else {
      this.setState({
        loading: false,
        offers: [],
        offersStats: null,
        error: null,
      });
    }
  };

  render() {
    const { searches, search, index, onChangeJobsOrLocations, onRemoveSearch } =
      this.props;
    const { offersOpened, offers, offersStats, loading, error } = this.state;

    return (
      <article className="ProfileSearch-Item">
        {searches.length > 1 && (
          <button
            className="ProfileSearch-Item-Delete"
            type="button"
            onClick={onRemoveSearch(index)}
          >
            <i className="icon material-icons">close</i> Supprimer
          </button>
        )}

        <div className="form-row">
          <div className="form-column medium-6">
            <JobSelect
              name={`jobs${index}`}
              value={search.jobs}
              onChange={onChangeJobsOrLocations(index, "jobs")}
            />
          </div>
          <div className="form-column medium-6">
            <LocationSelect
              name={`locations${index}`}
              value={search.locations}
              onChange={onChangeJobsOrLocations(index, "locations")}
            />
          </div>
        </div>

        {!!search.jobs.length && !!search.locations.length && (
          <div className="ProfileSearch-Offers">
            <OfferStats stats={offersStats} />

            <button
              className="ProfileSearch-Item-Consult"
              type="button"
              disabled={!offers.length}
              onClick={this.onOpenOffers}
            >
              <i className="icon material-icons">chevron_right</i>
              {offers.length
                ? `Consulter les ${offers.length} dernières offres`
                : `Aucune offre récente`}
            </button>
            <Modal
              isOpen={offersOpened}
              contentLabel="Offres"
              onRequestClose={this.onCloseOffers}
              className={{
                base: "Modal",
                afterOpen: "Modal_after-open",
                beforeClose: "Modal_before-close",
              }}
              overlayClassName={{
                base: "ModalOverlay",
                afterOpen: "ModalOverlay_after-open",
                beforeClose: "ModalOverlay_before-close",
              }}
            >
              <div className="Modal-Wrapper">
                <h2 className="Modal-Title">
                  Liste des offres récentes pour &#171; {formatSearch(search)}{" "}
                  &#187;
                </h2>
                <OfferStats stats={offersStats} />
                {loading ? (
                  <p className="Loading">Chargement des offres...</p>
                ) : error ? (
                  <p className="Error">Erreur lors du chargement des offres</p>
                ) : (
                  <Offers search={search} offers={offers} />
                )}
              </div>
              <button
                type="button"
                onClick={this.onCloseOffers}
                className="Modal-CloseButton"
              >
                <i className="icon material-icons">highlight_off</i>
              </button>
            </Modal>
          </div>
        )}
      </article>
    );
  }
}

const OfferStats = ({ stats }) => {
  return (
    !!stats && (
      <div className="ProfileSearch-Offers-Stats">
        <div className="ProfileSearch-Offers-Stat">
          <span className="stat">{stats.lastMonth}</span>
          <span className="label">
            offres depuis <br />
            un mois
          </span>
        </div>
        <div className="ProfileSearch-Offers-Stat">
          <span className="stat">{stats.lastWeek}</span>
          <span className="label">
            offres la semaine <br />
            dernière
          </span>
        </div>
        <div className="ProfileSearch-Offers-Stat">
          <span className="stat">{stats.lastDay}</span>
          <span className="label">
            offres sur <br />
            le dernier jour
          </span>
        </div>
      </div>
    )
  );
};

class IOffers extends Component {
  render() {
    const { jobs, locations, offers, organization } = this.props;
    return (
      <div className="Offers">
        <ol className="Offers-Items">
          {offers.length ? (
            offers.map((offer, index) => (
              <li key={index} className="Offers-Item">
                <article className="Offer">
                  <div className="Offer-Header">
                    <datetime className="Offer-Date">
                      Le {moment(offer.date).format("dddd DD MMMM YYYY")}
                    </datetime>
                    <div className="Offer-Labels">
                      {offer.jobIds.map((jobId) => (
                        <span className="Offer-Label Offer-Job">
                          {jobs[jobId]}
                        </span>
                      ))}
                      {offer.locationIds.map((locationId) => (
                        <span className="Offer-Label Offer-Location">
                          {locations[locationId]}
                        </span>
                      ))}
                    </div>
                  </div>
                  <h3 className="Offer-Title">{offer.title}</h3>
                  <div
                    className="Offer-Description"
                    dangerouslySetInnerHTML={{ __html: offer.description }}
                  />
                  {organization.show_offer_links_in_webapp ? (
                    <a
                      href={offer.link}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="Offer-Link"
                    >
                      Voir sur {offer.source}{" "}
                      <i className="icon material-icons">launch</i>
                    </a>
                  ) : (
                    <span className="Offer-LinkDisabled">
                      Annonce disponible sur {offer.source}
                    </span>
                  )}
                </article>
              </li>
            ))
          ) : (
            <p>Aucune offre récente.</p>
          )}
        </ol>
      </div>
    );
  }
}

const transformToObject = (options) =>
  _mapValues(_mapKeys(options, "id"), "name");
const getJobs = createSelector([(state) => state.jobs], (jobs) =>
  transformToObject(jobs)
);
const getLocations = createSelector([(state) => state.locations], (locations) =>
  transformToObject(locations)
);
const Offers = connect((state) => ({
  organization: state.organization,
  jobs: getJobs(state),
  locations: getLocations(state),
}))(IOffers);
