import _orderBy from "lodash/orderBy";
import React, { Component } from "react";
import { connect } from "react-redux";
import ReactSuperSelect from "react-super-select";
import { createSelector } from "reselect";

const CheckBox = (checked) => {
  return (
    <svg
      className="CheckBox"
      xmlns="http://www.w3.org/2000/svg"
      width="16px"
      height="16px"
      viewBox="0 0 16 16"
    >
      {checked && (
        <rect
          className="CheckBox-Selected"
          stroke="none"
          width="16"
          height="16"
          rx="2"
        />
      )}
      <rect
        width="13"
        height="13"
        x="1.5"
        y="1.5"
        stroke="#FFFFFF"
        fill="none"
        strokeWidth="3"
        rx="2"
      />
      <rect
        width="15"
        height="15"
        x=".5"
        y=".5"
        stroke="#828282"
        fill="none"
        rx="2"
      />
    </svg>
  );
};

const selectItemTemplate = (item, search) => {
  return (
    <div className="selectItem">
      <CheckBox checked={true} />
      <span>{item.name}</span>
    </div>
  );
};

class DefaultSelect extends Component {
  onChange = (options = []) => {
    this.props.onChange(options.map((option) => option.id));
  };

  getSelectedOptions = () => {
    const { options = [], value = [] } = this.props;
    return value
      .map((id) => options.find((option) => option.id === id))
      .filter((option) => !!option);
  };

  render() {
    const { options, label, property, value, ...others } = this.props;

    return (
      <div className="form-field JobAndLocationSelect">
        <ReactSuperSelect
          {...others}
          multiple={true}
          dataSource={options}
          onChange={this.onChange}
          keepOpenOnSelection={true}
          closeOnSelectedOptionClick={false}
          optionlabelKey="name"
          optionValueKey="id"
          matchProp="label"
          placeholder={label}
          clearSearchOnSelection={true}
          searchable={true}
          tags={true}
          initialValue={this.getSelectedOptions()}
          customOptionTemplateFunction={selectItemTemplate}
          searchPlaceholder="Recherche"
          noResultsString="Pas de résultats"
        />
        <span className="form-label">{label}</span>
        <i className="form-bar" />
      </div>
    );
  }
}

/**
 * Convertit la liste de métiers ou de bassins en liste d'options utilisable par le DefaultSelect
 */
const transformToOptions = (options) => {
  options = _orderBy(options, ["category", "name"], ["asc", "asc"]);

  return {
    options: options.map((option) =>
      option.category ? option : { ...option, category: "Autre" }
    ),
    groupBy: !!options.find((option) => !!option.category) ? "category" : null,
  };
};

const getJobsOptions = createSelector([(state) => state.jobs], (jobs) =>
  transformToOptions(jobs)
);
export const JobSelect = connect((state) => ({
  ...getJobsOptions(state),
  label: "Métier(s)",
  noResultsText: "Aucun métier correspondant",
  property: "metiers",
}))(DefaultSelect);

const getLocationsOptions = createSelector(
  [(state) => state.locations],
  (locations) => transformToOptions(locations)
);
export const LocationSelect = connect((state) => ({
  ...getLocationsOptions(state),
  label: "Bassin(s)",
  property: "locations",
  noResultsText: "Aucun bassin correspondant",
}))(DefaultSelect);
