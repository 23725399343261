import "moment/locale/fr";

import "./index.scss";

import moment from "moment";
import ReactDOM from "react-dom";
// import registerServiceWorker from './registerServiceWorker';
import { Provider } from "react-redux";

import { Root } from "./pages/Root.jsx";
import { configureStore } from "./store.js";

// Configuration de moment
moment.updateLocale("fr", {
  relativeTime: {
    future: "dans %s",
    past: "il y a %s",
    s: "quelques secondes restantes",
    m: "1 minute restante",
    mm: "%d minutes restantes",
    h: "1 heure restante",
    hh: "%d heures restantes",
    d: "1 jour restant",
    dd: "%d jours restants",
    M: "1 mois restant",
    MM: "%d mois restants",
    y: "1 an restant",
    yy: "%d ans restants",
  },
});

// Configuration du store Redux
const store = configureStore();

document.title = store.getState().organization.name + " - Sinad Emploi";

ReactDOM.render(
  <Provider store={store}>
    <Root />
  </Provider>,
  document.getElementById("root")
);

// Remove SW for now : https://github.com/facebookincubator/create-react-app/issues/2398
// registerServiceWorker();
