import auth from "./auth.js";
import search from "./search.js";

const reducers = [auth, search];

export const reducer = (state = {}, action = {}) => {
  reducers.forEach((reduce) => {
    state = reduce(state, action);
  });
  return state;
};
