import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import * as authActions from "../../ducks/auth.js";

export const Header = connect(
  ({ organization, managerUrl, user }) => ({
    organization,
    managerUrl,
    showAdmin: managerUrl && user.role !== "user",
  }),
  (dispatch) => ({
    authActions: bindActionCreators(authActions, dispatch),
  })
)(function ({
  history,
  managerUrl,
  showAdmin = false,
  authActions: { doLogout },
}) {
  const logoutAndExit = function () {
    history.push("/");
    doLogout();
  };

  return (
    <header className="App-Header">
      <ul className="App-Header-Links">
        {!!showAdmin && (
          <li className="App-Header-Link">
            <a href={managerUrl} target="_blank" rel="noreferrer noopener">
              Administration
            </a>
          </li>
        )}
        <li className="App-Header-Link">
          <a onClick={logoutAndExit}>Se déconnecter</a>
        </li>
      </ul>
    </header>
  );
});
