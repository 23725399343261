import { applyMiddleware, compose, createStore } from "redux";
import thunk from "redux-thunk";

import { reducer } from "./ducks/index.js";

let store;

export function getStore() {
  return store;
}

export function configureStore() {
  const middlewares = [thunk];
  const composeEnhancers =
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
  store = createStore(
    reducer,
    window.__DATA__ || {}, // Etat initial
    composeEnhancers(applyMiddleware(...middlewares))
  );
  store.dispatch({ type: "INIT" });
  return store;
}
