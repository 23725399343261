import cn from "classnames";
import RAlert from "react-s-alert";

const spacing = 10;

export function Alert() {
  return (
    <RAlert
      contentTemplate={AlertTemplate}
      stack={{ limit: 5, spacing }}
      offset={spacing}
      timeout={7000}
      effect="slide"
      position="top-right"
    />
  );
}
const alert =
  (fn, customFields = {}) =>
  (message, options = {}) =>
    fn(message, {
      ...options,
      customFields: {
        ...customFields,
        ...(options.customFields || {}),
      },
    });
Alert.warning = alert(RAlert.warning, {
  icon: <i className="icon material-icons">&#xE001;</i>,
  title: "Alerte",
  className: "s-alert-warning",
});
Alert.error = alert(RAlert.error, {
  icon: <i className="icon material-icons">&#xE002;</i>,
  title: "Erreur",
  className: "s-alert-error",
});
Alert.info = alert(RAlert.info, {
  icon: <i className="icon material-icons">&#xE626;</i>,
  title: "Info",
  className: "s-alert-info",
});
Alert.success = alert(RAlert.success, {
  icon: <i className="icon material-icons">&#xE5CA;</i>,
  title: "Succès",
  className: "s-alert-success",
});
Alert.deletion = alert(RAlert.success, {
  icon: <i className="icon material-icons">&#xE872;</i>,
  title: "Suppression",
  className: "s-alert-deletion",
});

Alert.close = RAlert.close;
Alert.closeAll = RAlert.closeAll;

function AlertTemplate({
  id,
  classNames,
  styles,
  message,
  handleClose,
  customFields,
}) {
  return (
    <div
      className={cn(classNames, customFields.className)}
      id={id}
      style={styles}
    >
      <div className="s-alert-icon">{customFields.icon}</div>
      <div className="s-alert-box-inner">
        <span className="s-alert-close" onClick={handleClose}>
          <i className="icon material-icons">&#xE5CD;</i>
        </span>
        <strong className="s-alert-title">{customFields.title}</strong>
        <div>{message}</div>
      </div>
    </div>
  );
}
