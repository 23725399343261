import _groupBy from "lodash/groupBy";
import _keyBy from "lodash/keyBy";
import _orderBy from "lodash/orderBy";
import _uniq from "lodash/uniq";
import _values from "lodash/values";
import moment from "moment";
import { createSelector } from "reselect";

import { getStore } from "../../store.js";

export const convertSearchesToInterests = (searches) => {
  const interests = [];
  searches
    .filter(({ jobs, locations }) => jobs.length && locations.length)
    .forEach((search, index) => {
      search.jobs.forEach((job) => {
        search.locations.forEach((location) => {
          interests.push({
            job_id: job,
            location_id: location,
            index,
          });
        });
      });
    });
  return interests;
};

export const convertInterestsToSearches = (interests) => {
  return _orderBy(_values(_groupBy(interests, "index")), "index").map(
    (list) => {
      const jobs = [];
      const locations = [];
      list.forEach(({ job_id, location_id }) => {
        jobs.push(job_id);
        locations.push(location_id);
      });
      return {
        jobs: _uniq(jobs),
        locations: _uniq(locations),
      };
    }
  );
};

const getJobsById = createSelector([(state) => state.jobs], (jobs) =>
  _keyBy(jobs, "id")
);
const getLocationsById = createSelector(
  [(state) => state.locations],
  (locations) => _keyBy(locations, "id")
);
export const formatSearch = (search) => {
  if (!search) {
    return "";
  }

  const state = getStore().getState();
  const jobsById = getJobsById(state);
  const locationsById = getLocationsById(state);

  const formatJobsOrLocations = (items, mapById) =>
    (items || [])
      .map((id) => {
        const item = mapById[id];
        return item ? item.name : null;
      })
      .filter((item) => !!item)
      .join(", ");

  const jobs = formatJobsOrLocations(search.jobs, jobsById);
  const locations = formatJobsOrLocations(search.locations, locationsById);

  if (!jobs) {
    return locations;
  }
  if (!locations) {
    return jobs;
  }

  return `${jobs} près de ${locations}`;
};

export const formatSubscriptionUntil = (recipient) => {
  return moment().endOf("day").isBefore(recipient.subscribed_until)
    ? `${moment(recipient.subscribed_until).fromNow(true)} jusqu'au`
    : `terminé le`;
};

export const isSubscriptionEnded = (recipient) =>
  !moment().endOf("Day").isBefore(recipient.subscribed_until) ||
  recipient.disabled;

const capitalize = (text = "") => {
  text = text.trim().toLowerCase();
  let result = "";
  for (let i = 0; i < text.length; i++) {
    if (i === 0 || /^(\s|-)$/.test(text.charAt(i - 1))) {
      result += text.charAt(i).toUpperCase();
    } else {
      result += text.charAt(i);
    }
  }
  return result;
};

export const formatName = (recipient) =>
  capitalize(recipient.firstname || "") +
  " " +
  (recipient.lastname || "").toUpperCase();
