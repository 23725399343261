import { Redirect, Route, Switch } from "react-router-dom";

import { Header } from "./Header.jsx";
import { RecipientAdd } from "./RecipientAdd.jsx";
import { RecipientEdit } from "./RecipientEdit.jsx";
import { Search } from "./Search.jsx";

export const App = (props) => {
  const parentPath = props.match.path.endsWith("/")
    ? props.match.path
    : props.match.path + "/";
  return (
    <div className="App">
      <aside className="App-Sidebar">
        <Search {...props} />
      </aside>

      <Header {...props} />

      <main className="App-Wrapper">
        <Switch>
          <Route path={parentPath} exact={true} component={RecipientAdd} />
          <Route
            path={parentPath + "recipient/:id"}
            component={RecipientEdit}
          />
          <Redirect to={parentPath} />
        </Switch>
      </main>
    </div>
  );
};
