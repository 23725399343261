import queryString from "query-string";
import React, { Component } from "react";
import { connect } from "react-redux";

import { Image } from "../components/Image.jsx";
import { Input } from "../components/Input.jsx";
import { Loader } from "../components/Loader.jsx";
import { get, post } from "../services/api.js";

const choiceOther = `Autre motif (précisez) :`;
const choices = [
  `J'ai retrouvé un emploi`,
  `Les offres que je reçois ne m'intéressent pas`,
  `Je ne suis plus intéressé(e) par ce service`,
  `Je ne suis plus à la recherche d'un emploi`,
  `Je reçois trop d'emails`,
  choiceOther,
];

const CheckBox = ({ checked, ...others }) => (
  <svg viewBox="0 0 16 16" {...others}>
    <circle cx="8" cy="8" r="6" stroke="#999" fill="none" />
    {checked && <circle cx="8" cy="8" fill="#38D1C7" r="4" />}
  </svg>
);

class EmailUnsubscribeComponent extends Component {
  state = {
    error: null,
    success: null,
    recipient: null,
    loading: false,
    showForm: false,

    reason: choices[0],
    reasonDetails: "",
  };

  componentWillMount() {
    const { recipient_id, email, mail_id } = this.getParams();
    if (!recipient_id || !email || !mail_id) {
      // Les paramètres ne sont pas renseignés
      this.setState({
        error: "Utilisateur inconnu",
      });
    } else {
      // On va chercher le destinataire pour savoir s'il existe et s'il est inscrit
      this.setState({
        loading: true,
      });
      get(`/mail/unsubscribe/recipient`, { recipient_id, email, mail_id })
        .then((recipient) => {
          if (recipient.disabled) {
            this.setState({
              success:
                "Vous êtes déjà désinscrit(e) de la liste de diffusion de SinadEmploi.",
              loading: false,
            });
          } else {
            this.setState({
              recipient,
              loading: false,
              showForm: true,
            });
          }
        })
        .catch(() => {
          this.setState({
            error: "Utilisateur inconnu",
            loading: false,
          });
        });
    }
  }

  getParams = () => {
    const { recipient, email, mail } = queryString.parse(
      this.props.location.search
    );
    return {
      recipient_id: recipient,
      email,
      mail_id: mail,
    };
  };

  onChangeReason = (ev) => {
    this.setState({
      reason: ev.target.value,
    });
  };

  onChangeReasonDetails = (ev) => {
    this.setState({
      reasonDetails: ev.target.value,
    });
  };

  onSubmit = (ev) => {
    ev.preventDefault();

    const { reason, reasonDetails } = this.state;

    // On soumet le formulaire
    this.setState({
      error: null,
      success: null,
      loading: true,
    });
    post(`/mail/unsubscribe/recipient`, {
      ...this.getParams(),
      reason: reason === choiceOther ? reasonDetails || "Autre" : reason,
    })
      .then(() => {
        this.setState({
          success: "Votre désinscription a bien été prise en compte.",
          loading: false,
          showForm: false,
        });
      })
      .catch((err) => {
        console.log(err);
        this.setState({
          error: "Une erreur est survenue",
          loading: false,
        });
      });
  };

  render() {
    const { organization } = this.props;
    const { success, error, loading, showForm, reason, reasonDetails } =
      this.state;

    return (
      <div className="EmailUnsubscribe">
        <div className="EmailUnsubscribe-Wrapper">
          <header className="EmailUnsubscribe-Header">
            <Image
              image={organization.logo}
              className="EmailUnsubscribe-Logo"
            />
          </header>

          <div className="EmailUnsubscribe-Content">
            {!!loading ? (
              <div>
                <Loader size="30px" />
              </div>
            ) : error ? (
              <p className="lead">{error}</p>
            ) : success ? (
              <p className="lead">{success}</p>
            ) : null}

            {showForm && (
              <form onSubmit={this.onSubmit} className="EmailUnsubscribe-Form">
                <p className="lead">
                  Attention : en confirmant votre désinscription, vous ne
                  bénéficierez plus du service de veille {organization.name}.
                </p>
                <p>
                  Afin d'améliorer notre service, merci de nous préciser la
                  raison principale de votre choix :
                </p>

                <ul className="EmailUnsubscribe-Form-CheckList">
                  {choices.map((choice) => (
                    <li
                      key={choice}
                      className="EmailUnsubscribe-Form-CheckList-Item"
                    >
                      <label className="EmailUnsubscribe-Form-Radio">
                        <input
                          type="radio"
                          name="reason"
                          checked={choice === reason}
                          value={choice}
                          onChange={this.onChangeReason}
                          className="EmailUnsubscribe-Form-Radio-Input"
                        />
                        <span className="EmailUnsubscribe-Form-Radio-Label">
                          {choice}
                        </span>
                        <CheckBox
                          checked={choice === reason}
                          className="EmailUnsubscribe-Form-Radio-Box"
                          width="20"
                          height="20"
                        />
                      </label>
                    </li>
                  ))}

                  {reason === choiceOther && (
                    <li>
                      <Input
                        placeholder="Précisions"
                        value={reasonDetails}
                        onChange={this.onChangeReasonDetails}
                      />
                    </li>
                  )}
                </ul>

                <button
                  type="submit"
                  className="EmailUnsubscribe-Form-Submit"
                  disabled={loading}
                >
                  Je confirme mon désabonnement
                </button>
              </form>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export const EmailUnsubscribe = connect(({ organization }) => ({
  organization,
}))(EmailUnsubscribeComponent);
