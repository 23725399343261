import _debounce from "lodash/debounce";
import React, { Component } from "react";

import { get } from "../../../services/api.js";

const initialState = {
  pending: false,
  emailAlreadyTaken: null,
};

export class MailAlreadyExistsChecker extends Component {
  state = {
    lastEmailChecked: null,
    emailAlreadyTaken: null,
  };

  pendingEmailCheck = null;

  checkEmail = _debounce((email) => {
    const { recipient, onPending, onEmailChecked } = this.props;
    const pendingEmailCheck = get(`/api/app/recipient/byEmail`, { email })
      .then((recipients) => {
        if (recipient.id) {
          recipients = recipients.filter(({ id }) => id !== recipient.id);
        }

        if (this.pendingEmailCheck === pendingEmailCheck) {
          this.pendingEmailCheck = null;
          const emailAlreadyTaken = recipients.length ? recipients[0].id : null;
          this.setState({
            lastEmailChecked: email,
            emailAlreadyTaken,
          });
          onEmailChecked(emailAlreadyTaken);
          onPending(false);
        }
      })
      .catch(() => {
        if (this.pendingEmailCheck === pendingEmailCheck) {
          this.pendingEmailCheck = null;
          this.setState({
            lastEmailChecked: null,
            emailAlreadyTaken: null,
          });
          onEmailChecked(null);
          onPending(false);
        }
      });
      this.pendingEmailCheck = pendingEmailCheck;
  }, 500);

  render() {
    const { recipient } = this.props;
    if (this.state.lastEmailChecked !== recipient.email) {
      this.checkEmail(recipient.email);
    }

    return null;
  }
}