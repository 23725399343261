import cn from "classnames";
import _debounce from "lodash/debounce";
import moment from "moment";
import React, { Component, useRef } from "react";
import FlipMove from "react-flip-move";
import { connect } from "react-redux";
import { Link, NavLink } from "react-router-dom";
import { bindActionCreators } from "redux";

import { Image } from "../../components/Image.jsx";
import { Loader } from "../../components/Loader.jsx";
import * as searchActions from "../../ducks/search.js";
import { convertInterestsToSearches, formatName } from "./util.js";

const RecipientStatus = ({ recipient }) => {
  // abonnement du destinataire en cours
  let status = "before";

  // le destinataire est désinscrit
  if (recipient.disabled) {
    status = "disabled";
  } else if (!moment().endOf("day").isBefore(recipient.subscribed_until)) {
    // l'abonnement du destinataire est dépassé
    status = "after";
  } else if (!!recipient.on_pause_since) {
    // l'abonnement du destinataire est en pause
    status = "paused";
  }

  let statusLabel = "";

  switch (status) {
    case "disabled":
      statusLabel = (
        <div className="Recipient-Status unsubsuscribed">
          <strong>Désinscription</strong>{" "}
          <span>le {moment(recipient.disabled_at).format("DD/MM/YY")}</span>
        </div>
      );
      break;
    case "after":
      statusLabel = (
        <div className="Recipient-Status unsubsuscribed">
          <strong>Terminé</strong>{" "}
          <span>
            depuis le {moment(recipient.subscribed_until).format("DD/MM/YY")}
          </span>
        </div>
      );
      break;
    case "paused":
      statusLabel = (
        <div className="Recipient-Status paused">
          <strong>En pause</strong>{" "}
          <span>
            depuis le {moment(recipient.on_pause_since).format("DD/MM/YY")}
          </span>
        </div>
      );
      break;
    case "before":
    default:
      statusLabel = (
        <div className="Recipient-Status">
          {moment(recipient.subscribed_until).fromNow(true)} (fin :{" "}
          {moment(recipient.subscribed_until).format("DD/MM/YY")})
        </div>
      );
      break;
  }

  return statusLabel;
};

const searchConnect = connect(
  ({ search, organization }) => ({ search, organization }),
  (dispatch) => ({
    searchActions: bindActionCreators(searchActions, dispatch),
  }),
  undefined,
  { pure: false }
);

const SearchBox = ({
  label,
  name,
  value,
  onChange,
  onSubmit,
  onSearchReset,
  ...others
}) => {
  const inputRef = useRef();
  return (
    <form className="SearchBox" onSubmit={onSubmit}>
      <div className="SearchBox-Wrapper">
        <div className="SearchBox-Field">
          <input
            type="search"
            name={name}
            id={name}
            value={value}
            className={cn("SearchBox-Input", { "has-value": !!value })}
            onChange={onChange}
            autoComplete="off"
            ref={inputRef}
            {...others}
          />
          <label className="SearchBox-Label" htmlFor={name}>
            {label}
          </label>
          <i className="SearchBox-Bar" />
        </div>
        <button type="submit" className="SearchBox-Submit">
          <i className="icon material-icons">&#xE8B6;</i>
        </button>

        <Link className="SearchBox-AddRecipient" to={`/`}>
          Ajouter un destinataire
        </Link>
        {value !== "" && (
          <button
            type="button"
            onClick={onSearchReset}
            className="SearchBox-Reset"
          >
            <i className="icon material-icons">&#xE5CD;</i>
          </button>
        )}
      </div>
    </form>
  );
};

class SearchComponent extends Component {
  state = {
    searchText: "",
    searchDisplay: false,
  };

  componentWillMount() {
    this.search();
  }

  onChangeSearch = (ev) => {
    const value = ev.target.value;
    this.setState({ searchText: value }, () => {
      this.search();
    });
  };

  onSearchReset = (ev) => {
    ev.preventDefault();
    this.setState({ searchText: "" });
    this.search();
  };

  onSearch = (ev) => {
    ev.preventDefault();
    this.search();
    this.setState({
      searchDisplay: !this.state.searchDisplay,
    });
  };

  onSearchDisplay = (ev) => {
    ev.preventDefault();
    this.setState({
      searchDisplay: !this.state.searchDisplay,
    });
  };

  search = _debounce(
    () => this.props.searchActions.doSearch(this.state.searchText),
    250
  );

  render() {
    const organization = this.props.organization;
    const { showSearchResult, total, loading = false } = this.props.search;
    const { searchText } = this.state;

    const showDisplayButton = !this.state.searchText && !!total;

    return (
      <div
        className={cn("Search", {
          "Search--withDisplayButton": showDisplayButton,
        })}
      >
        <header className="Search-Header">
          <Link to={`/`}>
            <Image className="LogoOrganization" image={organization.logo} />
          </Link>
        </header>

        <SearchBox
          label="Rechercher un destinataire"
          value={searchText}
          name="search"
          onChange={this.onChangeSearch}
          onSubmit={this.onSearch}
          onSearchReset={this.onSearchReset}
        />

        <div className="Search-Title">
          {showSearchResult
            ? `Résultats de la recherche`
            : `Mes destinataires en fin d'inscription`}
          &nbsp;<span className="Search-Count">({total})</span>
          {showDisplayButton && (
            <div className="Search-Display">
              <button
                className={cn("Search-Display-Button", {
                  "Search-Display-Button--mask": this.state.searchDisplay,
                })}
                onClick={this.onSearchDisplay}
              >
                {!this.state.searchDisplay ? "Afficher" : "Masquer"} la liste
              </button>
            </div>
          )}
          <div className={cn("Search-Loader", { loading })}>
            <Loader size="30px" />
          </div>
        </div>

        {this.state.searchDisplay || !showDisplayButton ? (
          <SearchResults />
        ) : (
          ""
        )}
      </div>
    );
  }
}

export const Search = searchConnect(SearchComponent);

const SearchResults = searchConnect(
  ({
    search: { recipients = [], error, offset, limit, total },
    searchActions: {
      doGetFirstPage,
      doGetPreviousPage,
      doGetNextPage,
      doGetLastPage,
    },
  }) => (
    <div className="SearchResults">
      {error ? (
        <div className="SearchResults-Error">{error}</div>
      ) : recipients.length ? (
        <div>
          <ol className="SearchResults-Items">
            <FlipMove
              appearAnimation="fade"
              leaveAnimation="fade"
              enterAnimation="elevator"
              maintainContainerHeight={true}
              duration={450}
              easing="cubic-bezier(.8, 0.2, 0.2, 1)"
            >
              {recipients.map((recipient) => (
                <li key={recipient.id} className="SearchResults-Item">
                  <Recipient recipient={recipient} />
                </li>
              ))}
            </FlipMove>
          </ol>
          <ul className="SearchResults-Pagination">
            <li className="SearchResults-Pagination-Item">
              <button type="button" disabled={!offset} onClick={doGetFirstPage}>
                <i className="icon material-icons">&#xE5DC;</i>
              </button>
            </li>
            <li className="SearchResults-Pagination-Item">
              <button
                type="button"
                disabled={!offset}
                onClick={doGetPreviousPage}
              >
                <i className="icon material-icons">&#xE5CB;</i>
              </button>
            </li>
            <li className="SearchResults-Pagination-Item">
              <span>{`${Math.floor(offset / limit) + 1}/${Math.ceil(
                (total * 1.0) / limit
              )}`}</span>
            </li>
            <li className="SearchResults-Pagination-Item">
              <button
                type="button"
                disabled={total <= offset + limit}
                onClick={doGetNextPage}
              >
                <i className="icon material-icons">&#xE5CC;</i>
              </button>
            </li>
            <li className="SearchResults-Pagination-Item">
              <button
                type="button"
                disabled={total <= offset + limit}
                onClick={doGetLastPage}
              >
                <i className="icon material-icons">&#xE5DD;</i>
              </button>
            </li>
          </ul>
        </div>
      ) : (
        <div className="SearchResults-NoResults">Aucun destinataire</div>
      )}
    </div>
  )
);

class Recipient extends Component {
  getRecipientIcon = (recipient) => {
    if (!!recipient.on_pause_since) {
      return <i className="Recipient-Icon material-icons">&#xE035;</i>;
    }
    return <i className="Recipient-Icon material-icons">&#xE853;</i>;
  };

  unactiveState = (recipient) =>
    !!recipient.on_pause_since || recipient.disabled;

  render() {
    const { recipient } = this.props;
    const searches = convertInterestsToSearches(recipient.interests);

    return (
      <NavLink
        key={recipient.id}
        to={`/recipient/${recipient.id}`}
        className="Recipient-Link"
        activeClassName="selected"
      >
        <article
          className={cn("Recipient-Article", {
            unactivated: this.unactiveState(recipient),
          })}
        >
          <div className="Recipient-Wrapper">
            {this.getRecipientIcon(recipient)}
            <i className="Recipient-Chevron material-icons">&#xE5CC;</i>
            <h2 className="Recipient-Name">{formatName(recipient)}</h2>

            <RecipientStatus recipient={recipient} />

            <div className="Recipient-SearchCount">
              {!!searches.length ? (
                <span>{`${searches.length} recherche${
                  searches.length > 1 ? "s" : ""
                } `}</span>
              ) : (
                <span>0 recherche</span>
              )}
            </div>
          </div>
        </article>
      </NavLink>
    );
  }
}
