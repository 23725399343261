import React, { Component } from "react";

import { Input } from "../../../components/Input.jsx";

/**
 * Partie du formulaire de destinataire gérant son profil (informations + recherches)
 */
export class ProfileInfos extends Component {
  onChangeTextProperty = (property) => (ev) => {
    const value = ev.target.value;
    this.props.onChangeRecipient({
      ...this.props.recipient,
      [property]: value,
    });
  };

  render() {
    const { recipient } = this.props;

    return (
      <fieldset className="ProfilForm form-fieldset">
        <legend>Informations destinataire</legend>

        <div className="form-row">
          <div className="form-column medium-6">
            <Input
              label="Prénom"
              property="firstname"
              model={recipient}
              onChange={this.onChangeTextProperty("firstname")}
              required
            />
          </div>
          <div className="form-column medium-6">
            <Input
              label="Nom"
              property="lastname"
              model={recipient}
              onChange={this.onChangeTextProperty("lastname")}
              required
            />
          </div>
        </div>
        <div className="form-row">
          <div className="form-column medium-6">
            <Input
              label="Email"
              property="email"
              model={recipient}
              onChange={this.onChangeTextProperty("email")}
              required
              type="email"
            />
          </div>
          <div className="form-column medium-6">
            <Input
              label="Téléphone Mobile (facultatif)"
              property="mobile"
              model={recipient}
              onChange={this.onChangeTextProperty("mobile")}
              type="tel"
            />
          </div>
        </div>
      </fieldset>
    );
  }
}
