export const Loader = ({ size = "150px" }) => (
  <svg
    width={size}
    height={size}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 100 100"
    preserveAspectRatio="xMidYMid"
    className="Loader-Svg"
  >
    <g>
      <animate
        attributeName="opacity"
        dur="2s"
        repeatCount="indefinite"
        begin="-1s"
        keyTimes="0;0.33;1"
        values="1;1;0"
      />
      <circle
        cx="50"
        cy="50"
        r="40"
        stroke="#5cffd6"
        fill="none"
        strokeWidth="6"
        strokeLinecap="round"
      >
        <animate
          attributeName="r"
          dur="2s"
          repeatCount="indefinite"
          begin="-1s"
          keyTimes="0;0.33;1"
          values="0;22;44"
        />
      </circle>
    </g>
    <g>
      <animate
        attributeName="opacity"
        dur="2s"
        repeatCount="indefinite"
        begin="0s"
        keyTimes="0;0.33;1"
        values="1;1;0"
      />
      <circle
        cx="50"
        cy="50"
        r="40"
        stroke="#38d1c7"
        fill="none"
        strokeWidth="6"
        strokeLinecap="round"
      >
        <animate
          attributeName="r"
          dur="2s"
          repeatCount="indefinite"
          begin="0s"
          keyTimes="0;0.33;1"
          values="0;22;44"
        />
      </circle>
    </g>
  </svg>
);
