import { get } from "../services/api.js";

const SEARCH_PENDING = "search/SEARCH_PENDING";
const SEARCH_SUCCESS = "search/SEARCH_SUCCESS";
const SEARCH_ERROR = "search/SEARCH_ERROR";

// On conserve la dernière promise non résolue pour comparer
// et s'assurer que l'on met bien à jour l'état avec la dernière recherche
let pendingSearch;

export function doSearch(q = "") {
  return function (dispatch, getState) {
    const limit = getState().search.limit;

    const isSearch = !!q;
    const url = isSearch
      ? `/api/app/recipient/search`
      : `/api/app/recipient/ending_soon`;
    const queryParams = {
      offset: 0,
      limit,
    };
    if (isSearch) {
      queryParams.q = q;
    }

    dispatch({
      type: SEARCH_PENDING,
    });

    const _pendingSearch = get(url, queryParams)
      .then(({ items, total }) => {
        if (pendingSearch === _pendingSearch) {
          pendingSearch = null;
          dispatch({
            type: SEARCH_SUCCESS,
            payload: {
              showSearchResult: isSearch,
              lastSearchText: q,
              offset: 0,
              loading: false,
              recipients: items,
              total,
              error: null,
            },
          });
        }
        return true;
      })
      .catch((err) => {
        if (pendingSearch === _pendingSearch) {
          pendingSearch = null;
          dispatch({
            type: SEARCH_ERROR,
            payload: {
              showSearchResult: isSearch,
              lastSearchText: q,
              offset: 0,
              loading: false,
              recipients: [],
              total: 0,
              error:
                err && err.message ? err.message : "Une erreur est survenue",
            },
          });
        }
      });
    pendingSearch = _pendingSearch;
  };
}

export function doGetFirstPage() {
  return getPreviousOrNextPage(() => 0);
}

export function doGetPreviousPage() {
  return getPreviousOrNextPage(({ offset, limit }) => offset - limit);
}

export function doGetNextPage() {
  return getPreviousOrNextPage(({ offset, limit }) => offset + limit);
}

export function doGetLastPage() {
  return getPreviousOrNextPage(
    ({ total, limit }) => total - (total % limit || limit)
  );
}

function getPreviousOrNextPage(getOffset) {
  return function (dispatch, getState) {
    const search = getState().search;
    const offset = getOffset(getState().search);
    const limit = search.limit;

    const isSearch = search.showSearchResult;
    const url = isSearch
      ? `/api/app/recipient/search`
      : `/api/app/recipient/ending_soon`;
    const queryParams = {
      offset,
      limit,
    };
    if (isSearch) {
      queryParams.q = search.lastSearchText;
    }

    dispatch({
      type: SEARCH_PENDING,
      payload: {
        offset,
      },
    });

    const _pendingSearch = get(url, queryParams)
      .then(({ items, total }) => {
        if (pendingSearch === _pendingSearch) {
          pendingSearch = null;
          dispatch({
            type: SEARCH_SUCCESS,
            payload: {
              loading: false,
              recipients: items,
              total,
              error: null,
            },
          });
        }
        return true;
      })
      .catch((err) => {
        if (pendingSearch === _pendingSearch) {
          pendingSearch = null;
          dispatch({
            type: SEARCH_ERROR,
            payload: {
              loading: false,
              recipients: [],
              error:
                err && err.message ? err.message : "Une erreur est survenue",
            },
          });
        }
      });
    pendingSearch = _pendingSearch;
  };
}

export function doRefresh() {
  return getPreviousOrNextPage(({ offset }) => offset);
}

export default function reducer(state = {}, action = {}) {
  switch (action.type) {
    case "INIT":
      return {
        ...state,
        search: {
          loading: true,
          lastSearchText: "",
          showSearchResult: false,
          error: null,
          recipients: [],
          offset: 0,
          limit: 5,
        },
      };
    case SEARCH_PENDING:
      return applySearchPending(state, action);
    case SEARCH_SUCCESS:
      return applySearchSuccess(state, action);
    case SEARCH_ERROR:
      return applySearchError(state, action);

    default:
      return state;
  }
}

function applySearchPending(state, action) {
  return {
    ...state,
    search: {
      ...state.search,
      loading: true,
      ...(action.payload || {}),
    },
  };
}

function applySearchSuccess(state, action) {
  return {
    ...state,
    search: {
      ...state.search,
      ...(action.payload || {}),
    },
  };
}

function applySearchError(state, action) {
  return {
    ...state,
    search: {
      ...state.search,
      ...(action.payload || {}),
    },
  };
}
