import _omit from "lodash/omit";

import { post } from "../services/api.js";

const LOGIN_PENDING = "auth/LOGIN_PENDING";
const LOGIN_SUCCESS = "auth/LOGIN_SUCCESS";
const LOGIN_ERROR = "auth/LOGIN_ERROR";
const LOGOUT = "auth/LOGOUT";

export function doLogin(login, password) {
  return function (dispatch) {
    dispatch({
      type: LOGIN_PENDING,
    });

    post("/api/app/login", {
      login,
      password,
    })
      .then((payload) =>
        dispatch({
          type: LOGIN_SUCCESS,
          payload,
        })
      )
      .catch((err) =>
        dispatch({
          type: LOGIN_ERROR,
          payload: err,
        })
      );
  };
}

export function doLogout() {
  return function (dispatch) {
    post("/api/app/logout")
      .then(() => dispatch({ type: LOGOUT }))
      .catch(() => {});
  };
}

export default function reducer(state = {}, action = {}) {
  switch (action.type) {
    case LOGIN_PENDING:
      return applyLoginPending(state, action);
    case LOGIN_SUCCESS:
      return applyLoginSuccess(state, action);
    case LOGIN_ERROR:
      return applyLoginError(state, action);

    case LOGOUT:
      return applyLogout(state, action);

    default:
      return state;
  }
}

function applyLoginPending(state) {
  return {
    ...state,
    login: {
      pending: true,
    },
  };
}

function applyLoginSuccess(state, action) {
  return {
    ..._omit(state, ["login"]),
    ...action.payload,
  };
}

function applyLoginError(state, action) {
  return {
    ...state,
    login: {
      error:
        action.payload && action.payload.message
          ? action.payload.message
          : "Une erreur est survenue",
    },
  };
}

function applyLogout(state) {
  return _omit(state, ["user", "xsrfToken", "jobs", "locations"]);
}
