import moment from "moment";

/**
 * Affichage d'une offre
 */
export const SubscriptionOffer = ({
  subscription,
  currentSubscription,
  recipient,
  onChange,
}) => {
  const id = subscription.duration;

  let duration, unit;
  if (subscription.duration % 30 === 0) {
    duration = subscription.duration / 30;
    unit = "mois";
  } else {
    duration = subscription.duration;
    unit = "jours";
  }

  const checked = subscription === currentSubscription;
  const endSubscriptionDate = moment
    .max(moment(), moment(recipient ? recipient.subscribed_until : new Date()))
    .add(subscription.duration, "day")
    .endOf("day");

  return (
    <label htmlFor={id} className="SubscriptionOffer">
      <input
        type="radio"
        id={id}
        name="offers"
        checked={checked}
        onChange={(ev) => onChange(ev.target.checked ? subscription : null)}
        className="SubscriptionOffer-Input"
        required
      />
      <div className="SubscriptionOffer-Label">
        <h3 className="SubscriptionOffer-Title">{subscription.name}</h3>
        <div className="SubscriptionOffer-Duration">
          <i className="icon material-icons">access_time</i>
          <div>
            {duration} {unit}
          </div>
        </div>
        Réception d'offres ciblées jusqu'au&nbsp;:&nbsp;
        <time
          className="SubscriptionOffer-Date"
          dateTime={endSubscriptionDate.format("YYYY-MM-DD")}
        >
          {endSubscriptionDate.format("DD/MM/YY")}
        </time>
      </div>
    </label>
  );
};
