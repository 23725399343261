import React from "react";
import { connect } from "react-redux";
import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";

import { Alert } from "../components/Alert.jsx";
import { App } from "./App/index.jsx";
import { EmailUnsubscribe } from "./EmailUnsubscribe.jsx";
import { Error } from "./Error.jsx";
import { Login } from "./Login.jsx";

class RootComponent extends React.Component {
  state = {
    error: null,
    infos: null,
  };

  componentDidCatch(error, infos) {
    console.log(error, infos);
    this.setState({ error, infos });
  }

  render() {
    const { organization, user } = this.props;
    if (!organization) {
      return <div>Aucune entreprise correspondante</div>;
    }

    const { error, infos } = this.state;

    return (
      <BrowserRouter>
        {!!error ? (
          <Error error={error} infos={infos} />
        ) : (
          <div>
            <Alert />
            {!!user ? (
              <Switch>
                <Route path="/email/unsubscribe" component={EmailUnsubscribe} />
                <Route path="/" component={App} />
                <Redirect to="/" />
              </Switch>
            ) : (
              <Switch>
                <Route path="/email/unsubscribe" component={EmailUnsubscribe} />
                <Route path="/" component={Login} />
                <Redirect to="/" />
              </Switch>
            )}
          </div>
        )}
      </BrowserRouter>
    );
  }
}

export const Root = connect(({ organization, user }) => ({
  organization,
  user,
}))(RootComponent);
