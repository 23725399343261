import cn from "classnames";

export const Input = ({
  model,
  label,
  property,
  onChange,
  value,
  type = "text",
  ...others
}) => {
  const inputValue = model ? model[property] || "" : value;
  return (
    <div className="form-field">
      <input
        className={cn("form-input", { "has-value": !!inputValue })}
        id={property}
        name={property}
        type={type}
        value={inputValue}
        onChange={onChange}
        {...others}
      />
      <label className="form-label" htmlFor={property}>
        {label}
      </label>
      <i className="form-bar" />
    </div>
  );
};
