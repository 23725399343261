import React, { Component } from "react";
import Modal from "react-modal";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import { Image } from "../components/Image.jsx";
import { Input } from "../components/Input.jsx";
import * as authActions from "../ducks/auth.js";

class LoginComponent extends Component {
  state = {
    login: "",
    password: "",
    showForgotPassword: false,
  };

  onSubmit = (ev) => {
    ev.preventDefault();

    this.props.authActions.doLogin(this.state.login, this.state.password);
  };

  onOpenForgotPasswordModal = (ev) => {
    ev.preventDefault();
    this.setState({ showForgotPassword: true });
  };

  onCloseForgotPasswordModal = () =>
    this.setState({ showForgotPassword: false });

  render() {
    const { organization, login = {} } = this.props;
    const { showForgotPassword } = this.state;

    return (
      <div className="Login">
        <div className="Login-Wrapper">
          <header className="Login-Header">
            <Image image={organization.logo} className="Login-Logo" />
          </header>

          <div className="Login-Content">
            {!!login.error && (
              <div className="Login-Message">{login.error}</div>
            )}

            <form onSubmit={this.onSubmit} className="Login-Form">
              <Input
                label="Identifiant"
                property="login"
                required
                value={this.state.login}
                onChange={(ev) => this.setState({ login: ev.target.value })}
              />
              <Input
                label="Mot de passe"
                id="password"
                name="password"
                type="password"
                required
                value={this.state.password}
                onChange={(ev) => this.setState({ password: ev.target.value })}
              />

              <button
                type="submit"
                className="Login-Form-Submit"
                disabled={login.pending}
              >
                Accéder
              </button>
            </form>

            <div className="Login-ForgotPassword">
              <a onClick={this.onOpenForgotPasswordModal}>
                mot de passe oublié ?
              </a>
              <Modal
                isOpen={showForgotPassword}
                onRequestClose={this.onCloseForgotPasswordModal}
                contentLabel="Mot de passe oublié"
                className={{
                  base: "Modal",
                  afterOpen: "Modal_after-open",
                  beforeClose: "Modal_before-close",
                }}
                overlayClassName={{
                  base: "ModalOverlay",
                  afterOpen: "ModalOverlay_after-open",
                  beforeClose: "ModalOverlay_before-close",
                }}
              >
                <div className="Modal-Wrapper">
                  <h2 className="Modal-Title Modal-Title--primary">
                    Vous avez oublié votre mot de passe ?
                  </h2>
                  <p>
                    Merci de contacter directement votre administrateur{" "}
                    {organization.name} afin de générer un nouveau mot de passe
                    pour votre compte.
                  </p>
                </div>
                <button
                  type="button"
                  onClick={this.onCloseForgotPasswordModal}
                  className="Modal-CloseButton"
                >
                  <i className="icon material-icons">highlight_off</i>
                </button>
                <footer className="Modal-Footer">
                  <button
                    className="form-submit"
                    type="button"
                    onClick={this.onCloseForgotPasswordModal}
                  >
                    Fermer
                  </button>
                </footer>
              </Modal>
            </div>
          </div>
        </div>

        <footer className="Login-Footer">
          <img
            src={process.env.PUBLIC_URL + "/images/logo-sinad.png"}
            alt="Sinad Emploi"
          />{" "}
          <br /> Copright 2017, tous droits réservés. Réalisé par{" "}
          <a
            href="http://millevolts.fr"
            rel="noopener noreferrer nofollow"
            target="_blank"
          >
            Mille Volts
          </a>
          .
        </footer>
      </div>
    );
  }
}

export const Login = connect(
  ({ organization, login }) => ({ organization, login }),
  (dispatch) => ({
    authActions: bindActionCreators(authActions, dispatch),
  })
)(LoginComponent);
