import _chunk from "lodash/chunk";
import moment from "moment";
import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { bindActionCreators } from "redux";

import { Alert } from "../../components/Alert.jsx";
import * as searchActions from "../../ducks/search.js";
import { post } from "../../services/api.js";
import { MailAlreadyExistsChecker } from "./ProfilForm/MailAlreadyExistsChecker.jsx";
import { ProfileInfos } from "./ProfilForm/ProfileInfos.jsx";
import { ProfileSearch } from "./ProfilForm/ProfileSearch.jsx";
import { SubscriptionOffer } from "./ProfilForm/SubscriptionOffer.jsx";
import { convertSearchesToInterests } from "./util.js";

const initialState = {
  recipient: {},
  subscription: null,
  searches: [
    {
      jobs: [],
      locations: [],
    },
  ],
  submitting: false,
  pending: false,
  emailAlreadyTaken: null,
};

class RecipientAddComponent extends Component {
  state = {
    ...initialState,
  };

  onPending = (pending) => this.setState({ pending });

  onChangeRecipient = (recipient) => this.setState({ recipient });

  onChangeSearches = (searches) => this.setState({ searches });

  onEmailChecked = (emailAlreadyTaken) => this.setState({ emailAlreadyTaken });

  onChangeSubscription = (subscription) => this.setState({ subscription });

  onSubmit = (ev) => {
    ev.preventDefault();

    if (this.state.emailAlreadyTaken) return; // Au cas où, on empêche l'envoi

    this.setState({
      submitting: true,
    });

    const { recipient, subscription, searches } = this.state;

    const data = {
      recipient: {
        ...recipient,
        interests: convertSearchesToInterests(searches),
      },
      subscription,
    };

    post("/api/app/recipient", data)
      .then(() => {
        Alert.success(`Le nouveau destinataire a bien été inscrit !`);
        this.props.searchActions.doRefresh();
        // L'url n'existe pas, cela permet de forcer la redirection vers cette même page
        this.props.history.replace("/done");
      })
      .catch((err) => {
        Alert.error(
          err && err.message ? err.message : "Une erreur est survenue"
        );
        this.setState({
          submitting: false,
        });
      });
  };

  render() {
    const { organization } = this.props;
    const { recipient, subscription, searches, submitting, pending, emailAlreadyTaken } =
      this.state;
    return (
      <div className="RecipientAdd">
        <header className="RecipientAdd-Header">
          <h1 className="RecipientAdd-Title">Ajout d'un destinataire</h1>
        </header>

        <MailAlreadyExistsChecker recipient={recipient} onPending={this.onPending} onEmailChecked={this.onEmailChecked} />

        <form className="RecipientAdd-Form" onSubmit={this.onSubmit}>
          <ProfileInfos
            recipient={recipient}
            onPending={this.onPending}
            onChangeRecipient={this.onChangeRecipient}
          />

          {!emailAlreadyTaken ? <>
          <ProfileSearch
            searches={searches}
            onPending={this.onPending}
            onChangeSearches={this.onChangeSearches}
          />}

          {!!organization.subscriptions &&
            !!organization.subscriptions.offers &&
            !!organization.subscriptions.offers.length && (
              <fieldset className="form-fieldset noborder">
                <legend>Choix de l'offre</legend>

                {_chunk(organization.subscriptions.offers, 3).map(
                  (subscriptions, index) => (
                    <div className="form-row" key={index}>
                      {subscriptions.map((_subscription) => (
                        <div
                          className="form-column medium-4"
                          key={_subscription.duration}
                        >
                          <SubscriptionOffer
                            subscription={_subscription}
                            currentSubscription={subscription}
                            onChange={this.onChangeSubscription}
                          />
                        </div>
                      ))}
                    </div>
                  )
                )}
              </fieldset>
            )}

          <footer className="form-footer">
            {moment().isAfter(organization.subscription_end_date) ? (
              <button className="form-submit" type="submit" disabled>
                Votre inscription à Sinad Emploi est terminée
              </button>
            ) : (
              <button
                className="form-submit"
                type="submit"
                disabled={submitting || pending}
              >
                Inscrire le destinataire
              </button>
            )}
          </footer>
        </>: <div className="ProfilForm-EmailAlreadyExists">
              Cette adresse existe déjà, {" "}
              <Link to={`/recipient/${emailAlreadyTaken}`}>modifiez ce destinataire</Link>
            </div>}
        </form>
      </div>
    );
  }
}

export const RecipientAdd = connect(
  ({ organization }) => ({ organization }),
  (dispatch) => ({
    searchActions: bindActionCreators(searchActions, dispatch),
  })
)(RecipientAddComponent);
