import chunk from "lodash/chunk";
import _pick from "lodash/pick";
import moment from "moment";
import React, { Component } from "react";
import Modal from "react-modal";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { bindActionCreators } from "redux";

import { Alert } from "../../components/Alert.jsx";
import * as searchActions from "../../ducks/search.js";
import { del, get, patch, post } from "../../services/api.js";
import { MailAlreadyExistsChecker } from "./ProfilForm/MailAlreadyExistsChecker.jsx";
import { ProfileInfos } from "./ProfilForm/ProfileInfos.jsx";
import { ProfileSearch } from "./ProfilForm/ProfileSearch.jsx";
import { SubscriptionOffer } from "./ProfilForm/SubscriptionOffer.jsx";
import {
  convertInterestsToSearches,
  convertSearchesToInterests,
  isSubscriptionEnded,
} from "./util.js";

class RenewForm extends Component {
  state = {
    lastOffer: null,
    subscription: null,
    submitting: false,
    showUnsubscribeModal: false,
  };

  componentWillMount() {
    this.initStateFromProps(this.props);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.recipient !== this.props.recipient) {
      this.initStateFromProps(nextProps);
    }
  }

  initStateFromProps = ({ recipient: { lastSubscription }, offers }) => {
    if (lastSubscription) {
      const lastOffer = offers.find(
        (offer) => offer.duration === -lastSubscription.duration
      );
      this.setState({
        lastOffer,
        subscription: lastOffer,
      });
    }
  };

  onChangeSubscription = (subscription) => this.setState({ subscription });

  onSubmit = (ev) => {
    ev.preventDefault();

    this.setState({
      submitting: true,
    });

    const { subscription } = this.state;

    Promise.all([
      post(
        `/api/app/recipient/${this.props.recipient.id}/subscription`,
        subscription
      ),
      new Promise((resolve) => setTimeout(resolve, 1000)),
    ])
      .then(([recipient, timeout]) => {
        Alert.success(`L'abonnement du destinataire a bien été renouvelé !`);
        this.setState({
          submitting: false,
        });
        this.props.updateRecipient(recipient);
      })
      .catch((err) => {
        Alert.error(
          err && err.message ? err.message : "Une erreur est survenue"
        );
        this.setState({
          submitting: false,
        });
      });
  };

  onTogglePause = () => {
    this.setState({
      submitting: true,
    });

    const { recipient } = this.props;
    const paused = !!recipient.on_pause_since;

    post(`/api/app/recipient/${recipient.id}/pause`, {
      pause: !paused,
    })
      .then((recipient) => {
        Alert.success(
          paused
            ? `L'abonnement du destinataire a repris !`
            : `L'abonnement du destinataire a été mis en pause !`
        );
        this.setState({
          submitting: false,
        });
        this.props.updateRecipient(recipient);
      })
      .catch((err) => {
        Alert.error(
          err && err.message ? err.message : "Une erreur est survenue"
        );
        this.setState({
          submitting: false,
        });
      });
  };

  onShowUnsubscribeModal = () => this.setState({ showUnsubscribeModal: true });

  onCloseUnsubscribeModal = () =>
    this.setState({ showUnsubscribeModal: false });

  onUnsubscribe = () => {
    this.setState({
      submitting: true,
    });

    del(`/api/app/recipient/${this.props.recipient.id}`)
      .then(() => {
        Alert.success(`Le destinataire a été désinscrit !`);
        this.setState({
          submitting: false,
        });
        this.props.searchActions.doRefresh();
        this.props.history.push("/");
      })
      .catch((err) => {
        Alert.error(
          err && err.message ? err.message : "Une erreur est survenue"
        );
        this.setState({
          submitting: false,
        });
      });
  };

  render() {
    const { recipient, offers, organization } = this.props;
    const { subscription, lastOffer, submitting, showUnsubscribeModal } =
      this.state;

    const isEnded = isSubscriptionEnded(recipient);
    const paused = !!recipient.on_pause_since;

    return (
      <div className="RecipientEdit-Offer">
        <header className="RecipientEdit-Offer-Header">
          <h1 className="RecipientEdit-Offer-Title">Abonnement</h1>
          {!recipient.disabled && !isEnded && (
            <ul className="RecipientEdit-Offer-Actions">
              <li className="RecipientEdit-Offer-Action">
                <button
                  type="button"
                  disabled={submitting}
                  onClick={this.onShowUnsubscribeModal}
                >
                  Désinscrire
                </button>
              </li>
              <li className="RecipientEdit-Offer-Action">
                <button
                  type="button"
                  disabled={submitting}
                  onClick={this.onTogglePause}
                >
                  {paused ? "Reprendre" : "Mettre en pause"}
                </button>
              </li>
            </ul>
          )}
        </header>

        <form className="RecipientEdit-Offer-Form" onSubmit={this.onSubmit}>
          <fieldset className="form-fieldset noborder">
            <legend>
              {isEnded
                ? `Renouvellement de l'offre`
                : `Prolongation de l'offre`}
            </legend>
            <p>
              {isEnded
                ? `L'abonnement du destinataire est terminé depuis le : `
                : `Offre actuelle du destinataire : ${
                    lastOffer ? lastOffer.name + ", " : ""
                  }`}
              <strong>
                {moment(recipient.subscribed_until).format("DD/MM/YY")}
              </strong>
            </p>
            {chunk(offers, 3).map((subscriptions, index) => (
              <div className="form-row" key={index}>
                {subscriptions.map((_subscription) => (
                  <div
                    className="form-column medium-4"
                    key={_subscription.duration}
                  >
                    <SubscriptionOffer
                      subscription={_subscription}
                      currentSubscription={subscription}
                      recipient={recipient}
                      onChange={this.onChangeSubscription}
                    />
                  </div>
                ))}
              </div>
            ))}
          </fieldset>

          {paused && (
            <div className="RecipientEdit-Offer-Paused">
              <div className="RecipientEdit-Offer-Paused-Wrapper">
                <p>
                  Abonnement en pause depuis le{" "}
                  {moment(recipient.on_pause_since).format("DD/MM/YY")}
                </p>
                <button
                  className="RecipientEdit-Offer-Paused-Button"
                  type="button"
                  onClick={this.onTogglePause}
                >
                  Reprendre l'abonnement
                </button>
              </div>
            </div>
          )}

          <div className="form-footer">
            {moment().isAfter(organization.subscription_end_date) ? (
              <button className="form-submit" type="submit" disabled>
                Votre inscription à Sinad Emploi est terminée
              </button>
            ) : (
              <button
                className="form-submit"
                type="submit"
                disabled={paused || submitting}
              >
                {isEnded ? (
                  this.state.submitting ? (
                    <span>Renouvellement en cours&hellip;</span>
                  ) : (
                    `Valider le renouvellement`
                  )
                ) : this.state.submitting ? (
                  <span>Prolongement en cours&hellip;</span>
                ) : (
                  `Prolonger l'abonnement`
                )}
              </button>
            )}
          </div>

          <Modal
            isOpen={showUnsubscribeModal}
            onRequestClose={this.onCloseUnsubscribeModal}
            contentLabel="Désinscription"
            className={{
              base: "Modal",
              afterOpen: "Modal_after-open",
              beforeClose: "Modal_before-close",
            }}
            overlayClassName={{
              base: "ModalOverlay",
              afterOpen: "ModalOverlay_after-open",
              beforeClose: "ModalOverlay_before-close",
            }}
          >
            <div className="Modal-Wrapper">
              <h2 className="Modal-Title Modal-Title--primary">
                Désinscription du destinataire
              </h2>
              <p>
                En validant vous désinscrivez le destinataire à la réception
                d'offres en rapport avec sa recherche d'emploi.
              </p>
            </div>
            <button
              type="button"
              onClick={this.onCloseUnsubscribeModal}
              className="Modal-CloseButton"
            >
              <i className="icon material-icons">highlight_off</i>
            </button>
            <footer className="Modal-Footer">
              <button
                className="form-submit"
                disabled={submitting}
                type="button"
                onClick={this.onUnsubscribe}
              >
                Désinscrire le destinataire
              </button>
            </footer>
          </Modal>
        </form>
      </div>
    );
  }
}

class ProfilEditForm extends Component {
  state = {
    recipient: {},
    searches: [
      {
        jobs: [],
        locations: [],
      },
    ],
    submitting: false,
    pending: false,
    emailAlreadyTaken: null,
  };

  componentWillMount() {
    this.initStateFromProps(this.props);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.recipient !== this.props.recipient) {
      this.initStateFromProps(nextProps);
    }
  }

  initStateFromProps = ({ recipient }) => {
    const searches = convertInterestsToSearches(recipient.interests);
    this.setState({
      recipient: _pick(recipient, [
        "id",
        "firstname",
        "lastname",
        "email",
        "mobile",
      ]),
      searches:
        !searches || !searches.length
          ? [
              {
                jobs: [],
                locations: [],
              },
            ]
          : searches,
    });
  };

  onPending = (pending) => this.setState({ pending });

  onEmailChecked = (emailAlreadyTaken) => this.setState({ emailAlreadyTaken });

  onChangeRecipient = (recipient) => this.setState({ recipient });

  onChangeSearches = (searches) => this.setState({ searches });

  onSubmit = (ev) => {
    ev.preventDefault();

    if (this.state.emailAlreadyTaken) return; // Au cas où, on empêche l'envoi

    this.setState({
      submitting: true,
    });

    const { recipient, searches } = this.state;

    const data = {
      ...recipient,
      interests: convertSearchesToInterests(searches),
    };

    Promise.all([
      patch(`/api/app/recipient/${this.props.recipient.id}`, data),
      new Promise((resolve) => setTimeout(resolve, 1000)),
    ])
      .then(([recipient, timeout]) => {
        Alert.success(`Le profil du destinataire a bien été mis à jour !`);
        this.setState({
          submitting: false,
        });
        this.props.updateRecipient(recipient);
      })
      .catch((err) => {
        Alert.error(
          err && err.message ? err.message : "Une erreur est survenue"
        );
        this.setState({
          submitting: false,
        });
      });
  };

  render() {
    const { recipient, searches, submitting, pending, emailAlreadyTaken } = this.state;
    const isEnded = isSubscriptionEnded(this.props.recipient);

    return (
      <div className="RecipientEdit-Infos">
        <header className="RecipientEdit-Infos-Header">
          <h1 className="RecipientEdit-Infos-Title">
            Fiche destinataire de {recipient.firstname} {recipient.lastname}
          </h1>
        </header>

        <MailAlreadyExistsChecker recipient={recipient} onPending={this.onPending} onEmailChecked={this.onEmailChecked} />
      
        {isEnded && (
          <div className="RecipientEdit-Infos-Banner">
            Ce destinataire est actuellement désinscrit.
          </div>
        )}
        <form className="RecipientEdit-Infos-Form" onSubmit={this.onSubmit}>
          <ProfileInfos
            recipient={recipient}
            onPending={this.onPending}
            onChangeRecipient={this.onChangeRecipient}
          />

          {!emailAlreadyTaken ? <>
            <ProfileSearch
              recipient={recipient}
              searches={searches}
              onPending={this.onPending}
              onChangeSearches={this.onChangeSearches}
            />

            <footer className="form-footer">
              <button
                className="form-submit"
                type="submit"
                disabled={submitting || pending}
              >
                {this.state.submitting ? (
                  <span>Sauvegarde en cours&hellip;</span>
                ) : (
                  "Sauvegarder"
                )}
              </button>
            </footer>
            </>: <div className="ProfilForm-EmailAlreadyExists">
              Cette adresse existe déjà, {" "}
              <Link to={`/recipient/${emailAlreadyTaken}`}>se rendre sur son profil</Link>
            </div>}
        </form>
      </div>
    );
  }
}

class RecipientEditComponent extends Component {
  state = {
    loading: true,
    error: null,
    recipient: null,
  };

  componentWillMount() {
    this.getRecipient(this.props.match.params.id);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.match.params.id !== this.props.match.params.id) {
      this.getRecipient(nextProps.match.params.id);
    }
  }

  getRecipient = (id) => {
    this.setState({
      loading: true,
      error: null,
      recipient: null,
    });
    get(`/api/app/recipient/${id}`)
      .then((recipient) =>
        this.setState({
          loading: false,
          recipient,
          error: recipient ? null : "Destinataire non trouvé",
        })
      )
      .catch((err) =>
        this.setState({
          loading: false,
          recipient: null,
          error: err && err.message ? err.message : "Une erreur est survenue",
        })
      );
  };

  updateRecipient = (recipient) => {
    this.setState({ recipient });
    this.props.searchActions.doRefresh();
  };

  render() {
    const { organization } = this.props;
    const { loading, error, recipient } = this.state;

    if (loading) {
      return null;
    }
    if (error) {
      return <div className="RecipientEdit-Error">{error}</div>;
    }
    return (
      <div className="RecipientEdit">
        <Link to={`/`} className="RecipientEdit-BackLink">
          <i className="icon material-icons">&#xE5C4;</i> Retour
        </Link>
        <ProfilEditForm
          {...this.props}
          recipient={recipient}
          updateRecipient={this.updateRecipient}
        />
        {!!organization.subscriptions &&
          !!organization.subscriptions.offers &&
          !!organization.subscriptions.offers.length && (
            <RenewForm
              {...this.props}
              offers={organization.subscriptions.offers}
              recipient={recipient}
              updateRecipient={this.updateRecipient}
            />
          )}
      </div>
    );
  }
}

export const RecipientEdit = connect(
  ({ organization }) => ({ organization }),
  (dispatch) => ({
    searchActions: bindActionCreators(searchActions, dispatch),
  })
)(RecipientEditComponent);
